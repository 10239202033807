import {Variants} from 'framer-motion';
import AnimationsEnum from '@/enums/AnimationsEnum';
import fadeIn from './fadeIn';

const fade: Variants = {
    ...fadeIn,
    exit: {
        opacity: 0,
        transition: {duration: AnimationsEnum.durationOut},
    },
};

export default fade;
