import fade from './content/fade';
import fadeIn from './content/fadeIn';
import list from './content/list';

const animations = {
    fade,
    fadeIn,
    list,
};

export default animations;
