import {Variants} from 'framer-motion';

type IList = {
    wrapper: Variants
    item: Variants
}

const list: IList = {
    wrapper: {
        initial: {opacity: 0},
        enter: {
            opacity: 1,
            transition: {
                ease: 'easeInOut',
                duration: 0.8,
                staggerChildren: 0.2,
            },
        },
    },
    item: {
        initial: {
            opacity: 0,
            translateY: 40,
        },
        enter: {
            opacity: 1,
            translateY: 0,
        },
    },
};

export default list;
