import {m} from 'framer-motion';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import React from 'react';
import useLocale from '@/hooks/useLocale';
import animations from '@/utils/animations';
import dataImages from './hero.dataImages';
import styles from './hero.module.css';

const Nav = dynamic(() => import('./nav'), {ssr: false});

const Hero: React.FC = () => {
    const locale = useLocale();

    return (
        <section id="hero" className={styles.hero} data-testid="component-hero">
            <picture className={styles.picture}>
                <Image
                    src="/images/hero.jpg"
                    width={1336}
                    height={749}
                    quality={100}
                    placeholder="blur"
                    blurDataURL={dataImages.hero}
                    alt=""
                />
            </picture>

            <article className={styles.container}>
                <m.h1
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={animations.fadeIn}
                    className={styles.heading}
                >
                    <span className="sr-only">Woodpall</span>
                    {locale?.hero.heading}
                </m.h1>
                <Nav />
                <span className={styles.arrow} />
            </article>
        </section>
    );
};

export default Hero;
